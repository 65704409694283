@import '../../../../../../styles/index.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;

  input {
    height: _var(--input-height, _var(--padding-12)); // 48px
    @include appearance(none);
    padding-right: rem-calc(40);
  }
}

.openButton {
  width: 100%;
  position: relative;
}

.dropdownIcon {
  @extend %inputDropdownIcon;
}
