@import '../../../../styles/index.scss';

.dropdown {
  @extend %dropdown;
  @extend %dropdown--nested;
  top: 100%;
  margin-top: _var(--dropdown-offset, _var(--padding-1_5));

  &--right {
    right: 0;
  }

  &--left {
    left: 0;
  }

  &--center {
    left: 50%;
    transform: translateX(-50%);
  }

  &--topLeft {
    top: initial;
    bottom: 100%;
    left: 0;
  }

  &--widgetEdit {
    top: rem-calc(45);
    right: rem-calc(12);
  }

  &--open {
    @extend %dropdownNested--hoverOpen;
  }

  &__item {
    @extend %dropdownItem;
  }

  &__action {
    @extend %dropdownLink;

    &--disabled {
      &:hover {
        opacity: $button-opacity-disabled;
        cursor: not-allowed;
      }
    }
  }
}

.iconButton,
.-trigger {
  cursor: pointer;

  &:focus ~ .dropdown,
  &.-active ~ .dropdown {
    @extend %dropdownNested--hoverOpen;
  }
}

.iconButton {
  height: rem-calc(27);
  width: rem-calc(27);
  padding: 0;
  border-radius: 50%;
  border: 1px solid $gray-20;

  > svg {
    margin: auto;
    transform: translateY(1px);

    path {
      fill: $gray-30;
    }
  }

  &--small {
    height: rem-calc(20);
    width: rem-calc(20);

    > svg {
      width: rem-calc(10);
    }
  }
}

.iconButton:focus {
  outline: 2px solid $blue-primary;

  > svg path {
    fill: $blue-primary;
  }
}
