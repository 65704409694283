@import '../../../styles/index.scss';

.button {
  padding: _var(--global-sm-padding) _var(--global-md-padding) rem-calc(12);
  font-size: $button-font-size;
  font-family: _var(--body-font-family, $font-family);
  line-height: _var(--global-padding);
  white-space: nowrap;
  border-width: 1px; // Sync with radio buttons
  border-style: solid;
  cursor: pointer;

  svg {
    max-width: _var(--global-padding);
    max-height: _var(--global-padding);

    path {
      fill: $gray-100;
    }
  }

  // Full Width Button
  &--fullWidth {
    width: 100%;
    display: block;
  }

  // Large Button
  &--large {
    padding: _var(--global-md-padding) _var(--global-padding);
  }

  // Small Button
  &--small {
    padding: rem-calc(6) _var(--global-sm-padding);
  }

  // Medium Icon Button
  &--mediumIcon {
    padding: _var(--global-sm-padding);
  }

  // Large Icon Button
  &--largeIcon {
    padding: _var(--global-md-padding);
  }

  // Small Icon Button
  &--smallIcon {
    padding: rem-calc(6);
  }

  // Primary Button
  &--primary {
    color: _var(--primary-foreground-color);
    background-color: _var(--primary-color);
    border-color: _var(--primary-color);

    svg {
      path {
        fill: _var(--primary-foreground-color);
      }
    }
  }

  // Secondary Button
  &--secondary,
  &--secondaryLight {
    background-color: $gray-20;
    border-color: $gray-20;
  }

  &--secondary {
    color: $gray-100;

    svg {
      path {
        fill: $gray-100;
      }
    }
  }

  &--secondaryLight {
    color: $gray-70;

    svg {
      path {
        fill: $gray-70;
      }
    }
  }

  // Tertiary Button
  &--tertiary,
  &--tertiaryLight {
    background-color: transparent;
  }

  &--tertiary {
    color: $gray-100;
    border-color: $gray-100;
  }

  &--tertiaryLight {
    color: $gray-30;
    border-color: $gray-60;

    svg path {
      fill: $gray-30;
    }
  }

  &--tertiaryDark {
    color: $gray-100;
    border-color: $gray-100;

    svg path {
      fill: $gray-100;
    }
  }

  // White Button
  &--white {
    color: $gray-100;
    background-color: $white;
    border-color: $white;
  }

  &--gray {
    color: $gray-70;
    background-color: #f5f5f7;
    border-width: 0;

    .icon svg path {
      fill: $gray-70;
    }
  }

  &--dark {
    color: _var(--gray-100);
    background-color: _var(--gray-1000);
    border-width: 0;

    .icon svg path {
      fill: _var(--gray-100);
    }
  }

  &--darkLight {
    color: _var(--gray-100);
    background-color: _var(--gray-800);
    border-width: 0;

    .icon svg path {
      fill: _var(--gray-100);
    }
  }

  // Normal text button
  &--text {
    @extend %textLikeButton;
    padding: 0;
    border-width: 0;
  }

  // Link text button
  &--link {
    @extend %textLikeButton;
    display: inline-flex;
    align-items: center;
    color: $gray-60;
    text-decoration: underline;
    padding: 0;
    border-width: 0;

    svg path {
      fill: $gray-60;
    }
  }

  // Delete Button
  &--danger {
    color: $white;
    background-color: $red-primary;
    border-width: 0;

    .icon svg path {
      fill: $white;
    }
  }

  // Text-Like Delete Button
  &--delete {
    @extend %textLikeButton;
    padding: 0;
    text-decoration: underline;
    transition: color 250ms;
    font-size: $small-font-size;
    line-height: $small-line-height;
    border-width: 0;

    svg > path {
      transition: fill 250ms;
    }

    &:hover {
      color: $red-primary;

      svg > path {
        fill: $red-primary;
      }
    }
  }

  &--pill {
    padding: rem-calc(9) rem-calc(12);
    font-size: rem-calc(15);
    line-height: $line-height;
    color: $gray-70;
    background-color: transparent;
    border-radius: rem-calc(30);
    border: 1px solid $gray-20;
    transition: all 250ms ease-in;

    svg {
      width: rem-calc(16);
      max-height: rem-calc(16);

      > path {
        fill: $gray-70;
      }
    }
  }

  &--pill:hover,
  &--pillActive {
    color: $blue-primary;
    border-color: $blue-primary;
    background-color: #e3ecfe;

    svg > path {
      fill: $blue-primary;
    }
  }

  &--accordion {
    border: 1px solid $gray-20;
  }

  &:not(:disabled):not(&--delete):not(&--text):not(&--danger):not(&--link):not(
      &--nonActive
    ):not(&--pill):not(.buttonV2):hover {
    background-color: $button-background-hover;
    border-color: $button-background-active;
    color: $white;

    svg path {
      fill: $white;
    }
  }

  &:not(:disabled):not(&--delete):not(&--text):not(&--danger):not(&--link):not(
      &--nonActive
    ):not(&--pill):not(.buttonV2):active {
    background-color: $button-background-active;
    border-color: $button-background-active;
    color: $white;

    svg {
      path {
        fill: $white;
      }
    }
  }

  &:disabled {
    opacity: $button-opacity-disabled;
    cursor: not-allowed;
  }
}

.button--link.button--small {
  font-size: $small-font-size;
  line-height: $small-line-height;
}

// Button notices are
// supported by all variants
.button--notice {
  @extend %noticeIcon;
}
.button--notice.button--pill {
  &::after {
    right: rem-calc(2);
  }
}

.buttonV2 {
  border-radius: _var(--button-border-radius, _var(--rounded-lg));
  display: inline-flex;
  justify-content: center;
  gap: _var(--padding-2);

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  // Large Button
  &--large {
    height: _var(--button-large-height, rem-calc(50));
    padding: _var(--button-large-padding, _var(--padding-3));
  }

  // Medium Button
  &--medium {
    height: _var(--button-medium-height, rem-calc(42));
    padding: _var(--button-medium-padding, _var(--padding-2_5));
  }

  // Small Button
  &--small {
    height: _var(--button-small-height, rem-calc(34));
    padding: _var(--button-small-padding, _var(--padding-2));
  }

  &.button--text {
    padding: 0;
    height: auto;
  }

  &.button--default {
    background: _var(
      --button-default-background-color,
      _var(--secondary-color)
    );
    border-color: _var(
      --button-default-background-color,
      _var(--secondary-color)
    );
    color: _var(
      --button-default-foreground-color,
      _var(--secondary-foreground-color)
    );

    &:not(:disabled):hover {
      background: _var(
        --button-default-background-color-hover,
        _var(--secondary-color-hover)
      );
      border-color: _var(
        --button-default-background-color-hover,
        _var(--secondary-color-hover)
      );
    }
  }

  &:not(:disabled):active,
  &:not(:disabled):focus {
    box-shadow: 0 0 0 2px _var(--body-background-color),
      0 0 0 4px _var(--button-default-ring-color, _var(--primary-color));
  }
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: sub;
  flex-shrink: 0;

  &--left {
    margin-right: _var(--padding-2);
  }

  &--right {
    margin-left: _var(--padding-2);
  }

  .buttonV2--large & {
    height: rem-calc(24);
    width: rem-calc(24);
  }

  .buttonV2--medium & {
    height: rem-calc(20);
    width: rem-calc(20);
  }

  .buttonV2--small & {
    height: rem-calc(16);
    width: rem-calc(16);
  }
}

.label {
  font-weight: _var(--button-font-weight, $font-weight-semibold);

  .buttonV2--large & {
    font-size: _var(--body-font-size);
    line-height: _var(--body-line-height);
  }

  .buttonV2--medium & {
    font-size: _var(--small-font-size);
    line-height: _var(--small-line-height);
  }

  .buttonV2--small & {
    font-size: _var(--xsmall-font-size);
    line-height: _var(--xsmall-line-height);
  }
}
