@import '../../../../styles/index.scss';

.label {
  align-items: flex-start;
}

.input {
  width: rem-calc(18);
  height: rem-calc(18);
  transform: translateY(#{rem-calc(1)});
  vertical-align: top;
}
