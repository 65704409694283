@import 'src/styles/index.scss';

$two-col-breakpoint: inline-rem-calc(400);
$three-col-breakpoint: inline-rem-calc(520);
$four-col-breakpoint: inline-rem-calc(640);
$item-label-line-height: _var(
  --item-label-line-height,
  _var(--body-line-height)
);
$item-heading-line-height: _var(
  --item-heading-line-height,
  _var(--line-height-xl-2)
);
$item-caption-line-height: _var(
  --item-caption-line-height,
  _var(--line-height-lg)
);

.container {
  @include exk-declare-padding-variables(_var(--item-box-shadow-size, 0px));
  @include exk-declare-border-variables;
  background: _var(--background-color, _var(--body-background-color));
  font-family: _var(--body-font-family);
  font-weight: _var(--body-font-weight);
  container-name: overview-section-container;
  container-type: inline-size;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  list-style: none;
  row-gap: _var(--row-gap, _var(--padding-6));
}

$item-padding-vertical: _var(--item-padding-vertical, _var(--padding-3));
$item-padding-horizontal: _var(--item-padding-horizontal, _var(--padding-3));

.item {
  display: block;
  box-shadow: _var(--item-box-shadow);
  border-radius: _var(--item-border-radius);
  border: _var(--item-border-width) solid
    _var(--item-border-color, _var(--border-color));
  padding: $item-padding-vertical $item-padding-horizontal;

  // &--highlight {}

  &__header {
    white-space: nowrap;
    position: relative;
    overflow: visible;
    line-height: 0;
  }

  &__icon {
    vertical-align: top;
    white-space: normal;
    position: absolute;
    right: auto;
    margin-left: rem-calc(4);
  }
}

.label,
.heading,
.caption {
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  display: block;
  font-family: _var(--item-label-font-family, _var(--body-font-family));
  font-size: _var(--item-label-font-size, _var(--body-font-size));
  font-weight: _var(--item-label-font-weight, _var(--body-font-weight));
  line-height: $item-label-line-height;
  color: _var(--item-label-font-color, _var(--gray-500));
  white-space: nowrap;

  &--loading {
    width: 50%;
    max-width: rem-calc(100);
    height: $item-label-line-height;
    @extend %aniLoading;
  }
}

.heading {
  max-width: 100%;
  display: inline-block; // Place alongside tooltip
  font-family: _var(--item-heading-font-family, _var(--body-font-family));
  font-size: _var(--item-heading-font-size, _var(--font-size-xl-2));
  font-weight: _var(--item-heading-font-weight, _var(--font-semibold));
  line-height: $item-heading-line-height;
  color: _var(--item-heading-font-color, _var(--body-font-color));

  &--loading {
    width: 80%;
    max-width: rem-calc(250);
    height: $item-heading-line-height;
    @extend %aniLoading;
  }
}

.caption {
  display: block;
  font-family: _var(--item-caption-font-family, _var(--body-font-family));
  font-size: _var(--item-caption-font-size, _var(--font-size-lg));
  font-weight: _var(--item-caption-font-weight, _var(--body-font-weight));
  line-height: $item-caption-line-height;
  color: _var(--item-caption-font-color, _var(--gray-500));

  &--loading {
    width: 38%;
    max-width: rem-calc(150);
    height: $item-caption-line-height;
    @extend %aniLoading;
  }
}

//
// Multi-Column Layout
//
.container--columns {
  border-width: 0;

  .item {
    margin: 0;
    padding: _var(--item-padding-vertical-up-md, $item-padding-vertical)
      _var(--item-padding-horizontal-up-md, $item-padding-horizontal);
  }

  // Vertically center to
  // large 1st item
  .item:not(:first-child) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @container overview-section-container (min-width: #{$two-col-breakpoint}) {
    .list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: _var(--column-gap, _var(--padding-6));
    }
  }

  @container overview-section-container (min-width: #{$three-col-breakpoint}) {
    .list {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  @container overview-section-container (min-width: #{$four-col-breakpoint}) {
    .list {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
