@import '/src/styles/index.scss';

.container {
  min-width: 100%; // Ensure never appears as 2nd column
  padding-top: _var(--padding-4);
  display: flex;
  align-items: center;
  font-size: rem-calc(13);
  line-height: rem-calc(17.76);
  font-weight: _var(--font-bold);
  color: _var(--accreditation-color, _var(--muted-font-color));
  @include disable-text-selection;

  span {
    vertical-align: middle;
  }
}

.appIcon {
  width: rem-calc(20);
  height: rem-calc(20);
  display: inline-block;
  margin-right: _var(--padding-2);
  cursor: pointer;

  .-isEditing & {
    cursor: auto;
  }
}

.linkText {
  color: _var(--accreditation-accent-color, _var(--secondary-color));
  text-decoration: underline;
  text-underline-offset: rem-calc(2);
  text-indent: rem-calc(5);
  cursor: pointer;

  .-isEditing & {
    cursor: auto;
  }
}
