.container {
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;

  > [class*='MuiInputBase-formControl'] {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    padding-right: 0;
    -webkit-box-align: center;
    position: relative;

    > [class*='MuiInputBase-input'] {
      letter-spacing: inherit;
      box-sizing: border-box;
      background: none;
      margin: 0px;
      -webkit-tap-highlight-color: transparent;
      min-width: 0px;
      width: 100%;
      animation-name: mui-auto-fill-cancel;
      animation-duration: 10ms;
    }
  }
}
