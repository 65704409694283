@import '../../../styles/index.scss';

.input,
.textarea {
  @extend %input;
  width: 100%;

  &--hasSuccess {
    @extend %inputSuccess;
  }

  &--hasError,
  &--hasError:focus,
  &--hasError:hover {
    @extend %inputError;
  }
}

.input {
  height: _var(--input-height, _var(--padding-12)); // 48px
}

.textarea {
  @extend %textareaInput;
}

// Radio / Checkbox Label
// layout and interations
.optionsInputLabel {
  display: flex;
  align-items: center;
  cursor: pointer;

  &--loading {
    cursor: default;
  }

  &__aside {
    flex: 0 1 auto;
    min-width: _var(--global-xlg-padding); // 30
    padding-right: _var(--global-sm-padding);
  }

  &__main {
    flex: 1 1 auto;
    max-width: calc(100% - #{rem-calc(45)});
    white-space: nowrap;
  }
}

.muiField {
  fieldset {
    display: none;
  }

  input {
    @extend %input;
    height: _var(--input-height, _var(--padding-12)); // 48px
  }

  &--hasError input,
  &--hasError input:focus,
  &--hasError input:hover {
    @extend %inputError;
  }
}

// Radio / Checkbox Heading
.optionsInputHeading {
  margin-bottom: _var(--global-xsm-padding);
  font-size: $font-size;
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;

  &--loading {
    width: rem-calc(140);
    height: $line-height;
    @extend %aniLoading;
  }
}

// Radio / Checkbox Subheading
.optionsInputSubHeading {
  display: block;
  font-size: $small-font-size;
  line-height: $small-line-height;
  color: $gray-60;
  overflow: hidden;
  text-overflow: ellipsis;

  &--loading {
    width: rem-calc(268);
    height: $small-line-height;
    @extend %aniLoading;
  }

  &--expanded {
    white-space: normal;
  }
}

.errorMessage {
  @extend %inputCaption;
  @extend %inputCaptionError;
}
