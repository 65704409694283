@import '/src/styles/index.scss';

$default-font-size: _var(--body-font-size, $font-size);
$default-line-height: _var(--body-line-height, $line-height);
$default-font-color: _var(--body-font-color, $body-font-color);

.container {
  display: flex;
  flex-direction: column;
  @include exk-declare-padding-variables;
  @include exk-declare-border-variables;
  border-radius: _var(--rounded);
  background: _var(--body-background-color);
}

.list {
  flex-grow: 2; // expand list to maximum height
  font-family: _var(--body-font-family);
}

.item {
  display: flex;
  justify-content: space-between;
  padding-block: _var(--list-item-vertical-padding, rem-calc(8));
  padding-inline: _var(--list-item-horizontal-padding, 0);
  color: $default-font-color;
  font-size: _var(--list-item-font-size, $default-font-size);
  line-height: $default-line-height;

  &--separator {
    border-bottom: 1px solid _var(--border-color, _var(--gray-200));
    padding-bottom: _var(--list-item-vertical-padding, rem-calc(8));
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  &--summary {
    font-size: _var(--big-font-size, $default-font-size);
    line-height: _var(--big-line-height, $default-line-height);
    padding-top: rem-calc(12);
    padding-bottom: rem-calc(12);
  }

  &:last-child {
    border-bottom-width: 0;
  }
}

.label {
  color: _var(--list-item-label-color, $default-font-color);
}

.text {
  color: _var(--list-item-text-color, $default-font-color);
}

.itemLoader {
  height: 20px;
  min-width: 50px;
  @extend %aniLoading;
}

.footer {
  display: flex;
  flex-direction: column;
  padding: _var(--padding-base) _var(--padding-right) _var(--global-md-padding)
    _var(--padding-left);
  background-color: $white;
  border-top: 1px solid _var(--border-color, _var(--gray-200));

  // Disabled due to wonkiness w/ header
  // position: sticky;
  // bottom: 0;

  &__action {
    justify-content: center;
  }

  &__info {
    margin-top: rem-calc(6);
    margin-bottom: 0;
    font-size: rem-calc(13);
    text-align: center;
    line-height: rem-calc(18);
    color: _var(--gray-500);
  }
}
