@import '../../../../styles/index.scss';

.container {
  border-width: 0;
  flex-wrap: nowrap;
}

.label {
  display: block;
  height: 100%;
  position: relative; // Contain radio input
}

// Hide input from view
// which is a proxy for a button
.radioInput {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2; // cover button
  border-color: _var(--radio-button-border-color);

  &:checked + .button {
    background-color: _var(--radio-button-active-color, _var(--primary-color));
    border-color: _var(
      --radio-button-active-border-color,
      _var(--radio-button-active-color, _var(--primary-color))
    );
    color: _var(
      --radio-button-active-font-color,
      _var(--primary-foreground-color)
    );
  }
}

.button {
  color: _var(--body-font-color);
  border-color: _var(--border-color);
  font-weight: _var(--font-bold);
  font-size: _var(--xsmall-font-size);
  line-height: _var(--xsmall-line-height);
  height: _var(--radio-button-height, _var(--padding-8)); // 32px
  padding: _var(--radio-button-vertical-padding, _var(--padding-1))
    _var(--radio-button-horizontal-padding, _var(--padding-4));

  // Add border to the first and last
  // button in the list
  [data-button-list-index]:first-child & {
    border-top-left-radius: _var(--radio-button-radius, _var(--rounded-lg));
    border-bottom-left-radius: _var(--radio-button-radius, _var(--rounded-lg));
  }
  [data-button-list-index]:last-child & {
    border-top-right-radius: _var(--radio-button-radius, _var(--rounded-lg));
    border-bottom-right-radius: _var(--radio-button-radius, _var(--rounded-lg));
  }

  // Remove double border in
  // between buttons
  border-left-width: 0;
  [data-button-list-index='0'] & {
    border-left-width: 1px; // Sync with button styles
  }
}
