@import '../../../styles/index.scss';

.container {
  position: relative;
  display: inline-block;
  max-height: 100%;

  &:hover,
  &:focus {
    .tooltip {
      display: block;
    }
  }

  .tooltip:hover {
    display: block;
  }

  > svg {
    cursor: pointer;
  }
}

.tooltip {
  display: none;
  position: absolute;
  width: rem-calc(230);
  max-width: rem-calc(230);
  padding: _var(--global-md-padding) _var(--global-padding);
  line-height: 1.5;
  background-color: $gray-100;
  font-size: $small-font-size;
  color: $white;
  z-index: $tooltipZindex;

  > * {
    margin: 0;
    font-size: $small-font-size;
  }

  &:before {
    content: '';
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: rem-calc(6);
  }

  &--top,
  &--bottom {
    left: 50%;
    margin: 0;
    transform: translateX(-50%);

    &:before {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--left,
  &--right {
    top: 50%;
    margin: 0 _var(--global-sm-padding);
    transform: translateY(-50%);

    &:before {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--top {
    bottom: 100%;

    &::before {
      bottom: rem-calc(-12);
      border-top-color: $gray-100;
    }
  }

  &--left {
    right: 100%;

    &::before {
      right: rem-calc(-12);
      border-left-color: $gray-100;
    }
  }

  &--right {
    left: 100%;

    &::before {
      left: rem-calc(-12);
      border-right-color: $gray-100;
    }
  }

  &--bottom {
    top: 100%;

    &::before {
      top: rem-calc(-12);
      border-bottom-color: $gray-100;
    }
  }

  // Children
  a {
    color: $blue-info;
    text-decoration: underline;
  }
}
