@import '../../../../styles/index.scss';

.container {
  list-style: none;
}

.item {
  display: block;
  border: 1px solid $gray-20;

  &:not(:last-child) {
    border-bottom-width: 0;
  }

  &.isChecked {
    border-color: transparent;
    outline: 2px solid $blue-primary;
  }
}

.label {
  padding: rem-calc(24) rem-calc(11) rem-calc(21);
}

.radioInput {
  width: _var(--global-padding);
  height: _var(--global-padding);
}
