@import '/src/styles/index.scss';

.switch {
  &:global(.MuiFormControlLabel-root) {
    margin-left: rem-calc(-5);
  }

  & > :global(.MuiSwitch-sizeMedium) {
    height: rem-calc(28);
    width: rem-calc(42);
    padding: rem-calc(5);

    & > :global(.MuiButtonBase-root) {
      top: rem-calc(2);
      left: rem-calc(2);
      padding: rem-calc(5);

      :global(.MuiSwitch-thumb) {
        width: rem-calc(14);
        height: rem-calc(14);
        padding: rem-calc(5);
        box-shadow: none;
      }
    }

    & > :global(.MuiSwitch-colorPrimary.Mui-checked) {
      color: $white;
      transform: translateX(rem-calc(14));

      & + :global(.MuiSwitch-track) {
        background-color: $blue-primary;
        opacity: 1;
      }
    }

    & > :global(.MuiSwitch-track) {
      border-radius: rem-calc(9);
      background-color: $gray-20;
      opacity: 1;
    }
  }

  & > :global(.MuiFormControlLabel-label) {
    font-weight: 500;
  }

  &--noLabel {
    &:global(.MuiFormControlLabel-root) {
      margin-right: rem-calc(-5);
    }
  }
}
