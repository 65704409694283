@import '/src/styles/index.scss';

.container {
  max-width: 100%;
  @include exk-declare-padding-variables;
  @include exk-declare-border-variables;
  background: _var(--background-color);

  &--inline {
    display: flex;
    flex-direction: column;

    // TODO need solution for
    // form scroll issues
    // overflow: auto;
  }
}

.fields {
  @extend %unstyledFieldset;
}

// Generic styles to overwrite
// default accordion styling
.questionGroups {
  display: block;
  margin-bottom: _var(--global-padding);
}

// Note layout used by
// sub-components as well
// (ie Form's Accordion)
.gridFields {
  display: grid;
  grid-gap: 0 _var(--global-padding);
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: min-content; // align top

  > .-expandedField {
    grid-column: 1 / -1;
  }
}

.actions {
  display: flex;

  &__primaryButton {
    flex: 1 1 auto;
    text-align: center;
  }

  &__shareButton {
    flex: 0 1 auto;
    margin-right: _var(--global-xsm-padding);
    align-items: center;

    svg > path {
      fill: _var(--button-default-foreground-color, _var(--secondary-foreground-color));
    }
  }
}

.container .accreditation {
  padding-left: 0;
  padding-right: 0;
}
