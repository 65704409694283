@import '/src/styles/index.scss';

.container {
  height: 100%;

  &--mediumUp {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.main {
  flex-grow: 1;
  @include exk-widget-grid;
  align-content: start;

  .sidebar + &--mediumUp {
    flex-basis: 61%;
    width: 61%; // NOTE: matches sidebar
  }

  // Multi-Column Layouts
  .sidebar + &--large {
    flex-basis: 75%;
    width: 75%; // NOTE: matches sidebar
  }
}

.sidebar {
  width: 100%;
  max-width: 100%;
  margin-bottom: _var(--global-xxlg-padding);
  @include exk-widget-grid-single-column;

  &--mediumUp {
    width: unset;
    flex: 1 1 39%;
    width: 39%; // NOTE: matches main
    margin-bottom: 0;
    grid-auto-rows: min-content; // Collapse rows to content height
  }

  &--large {
    flex: 1 1 25%;
    width: 25%; // NOTE: matches main
  }
}

// Single column layout
.cell {
  @include exk-widget-grid-cell;
}

.header {
  width: 100%;
}
