@import 'src/styles/index.scss';

// Settings

$select-option-height: 104;
$select-open-button-height: 80;
$select-group-gap: 10;

// Elements

.megaSelect {
  container-name: mega-select;
  container-type: inline-size;
}

.option {
  border: 1px solid $gray-10;
  min-height: rem-calc($select-option-height);
  border-radius: _var(--rounded-2xl);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: _var(--padding-4);
  gap: _var(--global-sm-padding);
  text-align: left;
  flex-shrink: 0;
  font-family: $font-family;
  position: relative;

  span,
  strong {
    display: block;
  }

  &--selected {
    outline: 2px solid $blue-primary;
  }

  &--hasBadge {
    padding-right: rem-calc(32);
  }

  @container mega-select (min-width: 420px) {
    gap: _var(--padding-4);
  }
}

.openButton {
  min-height: rem-calc($select-open-button-height);
  padding: rem-calc(12) _var(--global-sm-padding);
  border: 1px solid $gray-30;
  white-space: nowrap;

  &--noValue {
    border: 1px solid $gray-20;

    span,
    strong {
      color: $gray-50;
      font-weight: $font-weight-normal;
    }
  }

  @container mega-select (min-width: 420px) {
    padding: rem-calc(12) _var(--padding-4);
  }
}

.optionContent {
  .openButton & {
    overflow: hidden;

    span,
    strong {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

span.badge {
  position: absolute;
  border-radius: 50%;
  border: 1px solid _var(--gray-300);
  display: flex;
  align-items: center;
  justify-content: center;
  right: _var(--padding-2);
  top: _var(--padding-2);

  width: rem-calc(24);
  height: rem-calc(24);

  svg {
    width: rem-calc(12);
    height: rem-calc(12);
  }
}

.arrowIcon {
  margin-left: auto;
  flex-shrink: 0;

  .openButton--active & {
    transform: rotate(180deg);
    margin-top: rem-calc(2);
  }
}

.image {
  width: rem-calc(42);
  height: rem-calc(42);
  flex-shrink: 0;

  img,
  svg {
    width: 100%;
    height: 100%;
  }

  &--big {
    width: rem-calc(52);
    height: rem-calc(52);
  }

  @container mega-select (min-width: 420px) {
    width: rem-calc(52);
    height: rem-calc(52);

    &--big {
      width: rem-calc(72);
      height: rem-calc(72);
    }
  }
}

.content {
  z-index: 12;
  padding: _var(--padding-5);
  background: $white;
  width: 100%;
  font-family: $font-family;
  border-radius: $global-radius;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &--elevated {
    border: 1px solid $gray-30;
    box-shadow: 0px 2px 10px 0px rgba(18, 19, 26, 0.15);
    max-width: rem-calc(500);

    @container mega-select (min-width: 420px) {
      max-height: 45vh;
    }
  }

  &--hidden {
    display: none;
  }
}

.heading {
  margin-bottom: _var(--padding-5);
}

.group {
  display: flex;
  flex-direction: column;
  gap: rem-calc($select-group-gap);
  overflow-y: auto;
  width: 100%;
  flex: 1;
  // fix option's outline clipping
  padding: rem-calc(2);
  margin: rem-calc(-2);

  .content--elevated & {
    max-height: rem-calc($select-option-height * 4 + $select-group-gap * 3 + 4);
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: _var(--global-lg-padding);
  margin-top: _var(--padding-5);

  & > button {
    line-height: rem-calc(16);
  }
}

// Typography

.title {
  font-size: $big-font-size;
  line-height: $big-line-height;
  font-weight: $font-weight-bold;
  color: $gray-100;
}

.subtitle {
  color: $gray-50;
  font-size: $font-size;
  line-height: $line-height;
  margin-top: rem-calc(4);
}

.label {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight-bold;
  color: $gray-100;
}

.description {
  color: $gray-50;
  font-size: $small-font-size;
  line-height: $small-line-height;
  margin-top: rem-calc(3);
}

.cancelButton {
  font-size: $small-font-size;
}
