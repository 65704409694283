@import '../../../styles/index.scss';

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $gray-20;
  border-bottom: none;
  box-shadow: 0 1px $gray-20;
  background-color: $white;
}
