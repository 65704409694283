%chartLoaderContainer {
  flex-grow: 1;
  height: auto;
  max-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
