@import '/src/styles/index.scss';

$slider-handle-width: _var(--slider-handle-size, rem-calc(16));

.container {
  margin: rem-calc(9) 0;
  height: auto !important;

  // Prevent overflow when slider at max
  max-width: calc(100% - $slider-handle-width / 2);

  &--dark {
    --#{$product-namespace}-slider-track-color: #{_var(--gray-800)};
    --#{$product-namespace}-slider-handle-border-color: #{_var(--gray-800)};
  }

  // Background bar
  [class*='rc-slider-rail'] {
    height: _var(--slider-height, rem-calc(4));
    background-color: _var(--slider-rail-color, _var(--gray-100));
  }

  [class*='rc-slider-step'] {
    height: _var(--slider-height, rem-calc(4));
  }

  // Selected portion
  [class*='rc-slider-track'] {
    height: _var(--slider-height, rem-calc(4));
    background-color: _var(--slider-track-color, _var(--primary-color));
  }

  &.-hideTrack [class*='rc-slider-track'] {
    opacity: 0;
  }

  // Circular control
  [class*='rc-slider-handle'] {
    width: $slider-handle-width;
    height: _var(--slider-handle-size, rem-calc(16));
    margin-top: _var(--slider-handle-offset, rem-calc(-6));
    border: _var(--slider-handle-border-width, 4px) solid
      _var(--slider-handle-border-color, _var(--primary-color));
    opacity: 1;
    background: _var(
      --slider-handle-background-color,
      _var(--body-background-color)
    );

    &:hover {
      border-color: _var(--slider-handle-border-color, _var(--primary-color));
    }
  }
  [class*='rc-slider-handle-dragging'] {
    border-color: _var(--slider-handle-dragging-color, $blue-info) !important;
    box-shadow: 0 0 0 5px
      rgba(_var(--slider-handle-dragging-color, $blue-info), 0.45) !important;
  }

  // Hide track marker dots
  [class*='rc-slider-dot'] {
    display: none;
  }

  div[class*='rc-slider-mark'] {
    display: flex;
    justify-content: space-between;
    position: static;
    margin-top: _var(--slider-mark-margin-top, rem-calc(8));

    // Min/Max Value Labels
    [class*='rc-slider-mark-text']:first-child,
    [class*='rc-slider-mark-text']:last-child {
      position: static;
      transform: unset !important;
      flex: 0 1 auto;
      font-size: _var(--slider-mark-font-size, _var(--small-font-size));
      font-weight: _var(--slider-mark-font-weight, _var(--body-font-weight));
      line-height: _var(--slider-mark-line-height, _var(--small-line-height));
      color: _var(--slider-mark-font-color, _var(--gray-500));
    }

    // Min Value Label Only
    [class*='rc-slider-mark-text']:first-child {
      margin-left: 0 !important;
    }

    // Max value label Only
    // [class*='rc-slider-mark-text']:last-child {}
  }
}

.tooltip {
  padding-bottom: 0;
  opacity: 1;
  z-index: $floatingIndex;

  [class*='rc-tooltip-arrow'] {
    display: none;
  }

  [class*='rc-tooltip-content'] {
    margin-bottom: rem-calc(4);

    [class*='rc-tooltip-inner'] {
      padding: rem-calc(1) rem-calc(3);
      font-family: _var(--body-font-family);
      line-height: _var(
        --slider-tooltip-line-height,
        _var(--xsmall-line-height)
      );
      font-size: _var(--slider-tooltip-font-size, _var(--xsmall-font-size));
      font-weight: _var(--slider-tooltip-font-weight, _var(--font-semibold));
      color: _var(--slider-tooltip-font-color, _var(--gray-500));
      background-color: _var(
        --slider-tooltip-background-color,
        _var(--gray-100)
      );
      border: 1px solid
        _var(--slider-tooltip-border-color, _var(--border-color));
      box-shadow: unset;
      border-radius: _var(--slider-tooltip-border-radius, _var(--rounded));
    }
  }
}
