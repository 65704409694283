@import '/src/styles/index.scss';

.container {
  display: flex;
  padding: _var(--global-md-padding);
  background-color: $green-secondary;

  &--info {
    background-color: $blue-info;
    color: $white;

    > p {
      color: $white;
    }
  }

  &--warning {
    background-color: $warning-color;
  }

  &--unstyled {
    padding: 0;
    background-color: transparent;

    > p {
      color: $body-font-color;
    }
  }

  &__icon {
    margin-right: _var(--global-md-padding);
    min-width: rem-calc(18);
  }

  &__text {
    margin: 0;
    font-size: $small-font-size;
  }
}
