@import '/src/styles/index.scss';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  @include exk-widget-chart-section; // Default height settings
  max-height: _var(--max-height);
  @include exk-declare-padding-variables;
  @include exk-declare-border-variables;
  background: _var(--background-color);
}

.main {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  max-height: 100%;

  // Required for Chart.js
  // responsiveness to work
  margin: auto;
  position: relative;

  // Hide chart while loader is showing
  &--hide {
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }
}

// Ensure canvas does not overflow when
// when section header is present
.header + .main,
.header + .loader {
  max-height: calc(100% - #{_var(--section-header-height)});
}

.loader {
  @extend %chartLoaderContainer;
}
