$table-background-color: _var(
  --background-color,
  _var(--body-background-color)
);
$table-font-color: _var(--table-column-font-color, _var(--body-font-color));
$table-border-radius: _var(--table-border-radius);
$table-border-color: _var(--table-border-color, _var(--border-color-muted));
$table-column-font-size: _var(--table-column-font-size, _var(--body-font-size));
$table-column-padding-vertical: _var(
  --table-column-padding-vertical,
  _var(--padding-3)
);

%tableWrapper {
  width: 100%;
  border: _var(--table-border-width) solid $table-border-color;
  border-radius: $table-border-radius;
  background-color: $table-background-color;
}

%table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  text-align: left;
  color: $table-font-color;
  font-size: $table-column-font-size;
  font-weight: _var(--body-font-weight);
}

%headerColumn {
  padding-top: $table-column-padding-vertical;
  padding-bottom: $table-column-padding-vertical;
  padding-left: _var(--table-column-padding-left, _var(--padding-4));
  padding-right: _var(--table-column-padding-right, _var(--padding-4));
  background-color: _var(
    --table-header-background-color,
    $table-background-color
  );
  font-family: _var(--table-header-font-family, _var(--body-font-family));
  font-size: _var(--table-header-font-size, $table-column-font-size);
  line-height: _var(--table-header-line-height, _var(--body-line-height));
  font-weight: _var(--table-header-font-weight, _var(--body-font-weight));
  color: _var(--table-header-font-color, $table-font-color);
  border: 1px solid $table-border-color;
  border-left-width: _var(--table-horizontal-border-width, '1px');
  border-right-width: _var(--table-horizontal-border-width, '1px');

  &:first-child {
    border-top-left-radius: $table-border-radius;
  }

  &:last-child {
    border-top-right-radius: $table-border-radius;
  }
}

%bodyColumn {
  padding-top: $table-column-padding-vertical;
  padding-bottom: $table-column-padding-vertical;
  padding-left: _var(--table-column-padding-left, _var(--padding-4));
  padding-right: _var(--table-column-padding-right, _var(--padding-4));
  border: 1px solid $table-border-color;
  border-left-width: _var(--table-horizontal-border-width, '1px');
  border-right-width: _var(--table-horizontal-border-width, '1px');
}
