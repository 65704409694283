// Apply to all lists
// top level and nested
%dropdown {
  padding: _var(--padding-1) 0;
  margin: 0;
  list-style: none;
  position: relative;
  background-color: _var(
    --dropdown-background-color,
    _var(--body-background-color)
  );
  border: 1px solid _var(--dropdown-border-color, _var(--gray-100));
  border-radius: _var(--dropdown-radius, _var(--rounded-lg));
  box-shadow: _var(--dropdown-shadow);
}

%dropdown--nested {
  display: none; // hide by default
  position: absolute;
  z-index: 7;
  // Set `top` to height of container
}

// Apply to ul li:hover > ul
%dropdownNested--hoverOpen {
  display: block;
}

// Apply to all list items
// top level and nested
%dropdownItem {
  display: block;
  min-width: rem-calc(200);

  // Add divider line
  &:not(:first-child)::before {
    content: '';
    display: block;
    height: 1px;
    background-color: _var(--dropdown-divider-color);
  }

  // Remove divider for hovers
  &:hover::before {
    background-color: transparent;
  }
}

// Apply to: ul ul li
// %dropdownItem--topLevel {
//   width: rem-calc(170);
//   display: list-item;
//   position: relative;
// }

// Apply to: ul ul ul li
// for 2nd, 3rd, or deeper levels
// %dropdownItem--deepLevel {
//   position: relative;
//   top: rem-calc(-60);
//   left: rem-calc(170);
// }

%dropdownLink {
  display: block;
  padding: _var(--dropdown-link-vertical-padding, _var(--padding-2))
    _var(--dropdown-link-horizontal-padding, _var(--padding-4));
  font-size: _var(--dropdown-link-font-size, _var(--body-font-size));
  line-height: _var(--dropdown-link-line-height, _var(--body-line-height));
  color: _var(--body-font-color);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $white;
    background-color: $blue-primary;
  }
}

// Change this in order to change the Dropdown symbol
// li > a:after { content:  ' +'; }
// li > a:only-child:after { content: ''; }
