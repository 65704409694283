@import '/src/styles/index.scss';

.canvasLoader {
  height: rem-calc(400);
  @extend %aniLoading;
}

.title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
  font-family: _var(--section-heading-font-family, _var(--body-font-family));
  font-size: _var(--section-heading-font-size, _var(--font-size-xl));
  line-height: _var(--section-heading-line-height, _var(--line-height-xl));
  font-weight: _var(--section-heading-font-weight, _var(--font-semibold));
  color: _var(--section-heading-font-color, _var(--body-font-color));

  &__icon {
    margin-left: rem-calc(8);
  }
}
