@import '../../../../../../../styles/index.scss';

.item {
  @extend %visualizationsCompareListItem;
  display: block;
  padding: 0;

  &.-last .container__barChart__graphic {
    border-bottom-width: 0;
  }
}

.graphic {
  @extend %visualizationsCompareGraphicColumn;
  overflow: hidden;
}

.bar {
  @extend %visualizationsCompareGraphicColumn__bar;
  @extend %visualizationsCompareGraphicColumn__bar--right;
  max-width: 100%;
  flex: 1 0 100%;
  height: rem-calc(7);
}

.barAmount {
  @extend %visualizationsCompareGraphicColumn__amount;
  color: $gray-50;
}

.barValue,
.barValue--loading {
  @extend %visualizationsCompareGraphicColumn__bar__value;
}

.barValue--loading {
  @extend %aniLoading;
  width: 33%;
}

.text,
.item > span:first-child {
  text-align: left;
  margin-bottom: 0;
  color: $gray-60;
}
