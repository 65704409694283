@import '../../../../../styles/index.scss';

.panelHeading {
  @extend %togglePanel;
  min-height: rem-calc(43);
  margin-bottom: _var(--global-sm-padding);
  border-bottom: 1px solid $gray-20;

  &--toggleable {
    cursor: pointer;
  }

  &__text {
    @extend %togglePanel__text;
    margin-bottom: rem-calc(2);
    font-size: $h5-font-size;
    line-height: $h4-line-height;
    color: _var(--heading-font-color, _var(--body-font-color));
  }

  &__icon {
    @extend %togglePanel__icon;
    margin-left: auto;
    background-color: $white;
    cursor: pointer;
  }

  &--open &__icon {
    @extend %togglePanel__icon--open;
  }
}

.panelContent {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  transition: opacity 300ms linear;

  &--open {
    position: static;
    visibility: visible;
    opacity: 1;
  }
}

.panelHeading + .panelContent--open {
  margin-top: _var(--global-padding);
}
