@import '/src/styles/index.scss';

.container {
  height: 100%;
}

.main {
  height: 100%;
  @include exk-widget-grid;
}

.cell {
  @include exk-widget-grid-cell;
}
