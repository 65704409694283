@import '/src/styles/index.scss';

.container {
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  border: 1px solid $gray-30;

  &:not(&--flat) {
    box-shadow: 0 rem-calc(2) rem-calc(8) rgba(0, 0, 0, 0.2);
  }

  &--grid {
    display: inline-flex;
    flex-wrap: wrap;
    box-shadow: none;
    border-width: 0;

    > *:not(:last-child) {
      margin-right: _var(--global-padding);
    }
  }

  &--flat {
    box-shadow: unset;
  }

  &--row {
    display: flex;
    box-shadow: unset;
    border-width: 0;
    width: 100%;

    > .item {
      flex-grow: 2;
    }
  }
}

.item {
  display: block;
}
