@import '../../../../../styles/index.scss';

.container {
  @include exk-declare-padding-variables;
  @include exk-declare-border-variables;
  // overflow-x: auto !important; // TODO disable for mobile table support
  // overflow-y: visible !important;
  overflow: visible !important;
  overflow-x: auto !important;
  background: $table-background-color;
}

//
// Elements
//

.tableWrapper {
  @extend %tableWrapper;
}

.table {
  max-width: 100%;
  @extend %table;
}

.headerColumn {
  @extend %headerColumn;

  &--icon {
    position: relative;
    padding-right: _var(--padding-8);
  }

  &__icon {
    position: absolute;
  }
}

.bodyColumn {
  @extend %bodyColumn;
}
