@import '../../../../styles/index.scss';

.container {
  display: inline-flex;
  padding: rem-calc(2);
  font-size: $small-font-size;
  color: $gray-80;
  background-color: $gray-20;
  border-radius: $global-radius;

  label {
    cursor: pointer;
    white-space: nowrap;
  }

  &__input {
    height: 1px;
    width: 1px;
    border: 0;
    visibility: hidden;
    overflow: hidden;

    // Selected option
    &:checked + .text {
      color: $gray-100;
      background-color: $white;

      > svg path {
        fill: $gray-100;
      }
    }
  }
}

.text {
  display: inline-flex;
  align-items: center;
  padding: rem-calc(3) rem-calc(8);
  line-height: rem-calc(20);
  border-radius: $global-radius;
  color: $gray-60;
  transition: all ease-in-out 200ms;
  cursor: pointer;

  > svg {
    display: inline-block;
    margin-left: _var(--global-xsm-padding);

    path {
      fill: $gray-60;
    }
  }
}
