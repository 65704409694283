@import '../../../../styles/index.scss';

.container {
  position: relative;
}

.select,
select.select {
  height: _var(--input-height, _var(--padding-12)); // 48px
  padding-right: _var(--padding-10); // 40px
  padding-top: 0;
  padding-bottom: 0;
  text-indent: _var(--input-padding-left, _var(--padding-4));
  border-radius: _var(--input-border-radius, _var(--rounded-lg));
  background-color: _var(
    --input-background-color,
    _var(--body-background-color)
  );
  @include appearance(none);
}

.dropdownIcon {
  @extend %inputDropdownIcon;
}

// Fix Firefox doubling text indent fix
// https://stackoverflow.com/questions/28108434/firefox-select-element-doubles-text-indent-value
@-moz-document url-prefix() {
  select.select {
    text-indent: _var(--global-sm-padding);
  }
}
