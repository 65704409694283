@import 'src/styles/index.scss';

.header {
  // Varible set via widget
  // settings global styles
  min-height: _var(--section-header-height);
}

.editableText {
  cursor: text;
}
