@import '../../../../styles/index.scss';

.container {
  display: block;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: _var(--padding-1);
}

.heading {
  display: flex;
  margin-bottom: 0;
  font-family: _var(--body-font-family);
  font-size: _var(--label-font-size, _var(--small-font-size));
  line-height: _var(--label-line-height, _var(--small-line-height));
  font-weight: _var(--label-font-weight, _var(--body-font-weight));
  color: _var(--label-font-color, _var(--body-font-color));

  &--small {
    font-size: _var(--xsmall-font-size);
    line-height: _var(--xsmall-line-height);
  }

  &__icon {
    margin-left: _var(--padding-3);
    max-height: _var(--padding-5);

    > svg {
      max-height: 100%;
    }
  }
}

.caption {
  @extend %inputCaption;

  &--hasSuccess {
    @extend %inputCaptionSuccess;
  }
}
