@import '/src/styles/index.scss';

$twoColumnBarsLegendLayoutBreakpoint: 700px;

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  @include exk-widget-chart-section; // Default height settings
  max-height: _var(--widget-preview-height, _var(--max-height));
  overflow: hidden;
  @include exk-declare-padding-variables;
  @include exk-declare-border-variables;
  background: _var(--background-color);
  container-type: inline-size;
  container-name: pie-doughnut-chart;
}

.main {
  flex-grow: 1;
  flex-shrink: 0;
  max-height: 100%;

  &--hide {
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }

  &__graphic {
    width: 100%;
    height: 100%;
    min-height: _var(--min-chart-height);

    // Required for Chart.js
    // responsiveness to work
    margin: auto;
    position: relative;
  }

  &__barsLegend {
    padding: _var(--global-padding) _var(--global-lg-padding) 0;
    margin-bottom: 0;
    overflow: auto;
    max-height: 100%;
  }

  // Single column layout
  // with bars legend
  &.-barsLegend {
    .main__graphic {
      height: 67%;
    }

    .main__barsLegend {
      height: 33%;
    }
  }

  @container pie-doughnut-chart (min-width: #{$twoColumnBarsLegendLayoutBreakpoint}) {
    &.-barsLegend {
      display: flex;
      flex-wrap: wrap;

      .main__graphic {
        height: 100%;
        width: calc(100% - max(400px, 40%));
      }

      .main__barsLegend {
        width: max(400px, 40%);
        height: auto;
        align-self: center;
      }
    }
  }
}

// Ensure canvas does not overflow when
// when section header is present
.header + .main:not(.-barsLengend) {
  max-height: calc(100% - #{_var(--section-header-height)});
}

//
// Canvas
//
.canvas {
  max-width: 100%;

  &__centerText > * {
    display: block;
    max-width: rem-calc(140);
    text-align: center;
    font-size: rem-calc(18);
    line-height: 1.36;
    font-family: $body-font-family;
    font-weight: $font-weight-normal;
    color: $gray-70;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
