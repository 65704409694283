@import '/src/styles/index.scss';

$treemap-text-shadow: rgba(0, 0, 0, 0.45);

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  @include exk-widget-chart-section; // Default height settings
  max-height: _var(--max-height);
  @include exk-declare-padding-variables;
  @include exk-declare-border-variables;
  background: _var(--background-color);

  // Reveal/Hide tooltip styles
  svg.highcharts-root.-hideTooltip .highcharts-tooltip {
    display: none;
  }
}

// Ensure canvas does not overflow when
// when section header is present
.header + .canvas,
.header + .loader {
  max-height: calc(100% - #{_var(--section-header-height)});
}

.highlightLabel {
  text-align: center;

  p,
  strong {
    color: $white;
    font-family: $body-font-family;
    text-shadow: 1px 2px 0 $treemap-text-shadow;
  }

  // Heading Value
  strong {
    font-size: rem-calc(17);
    line-height: 1.1;
    text-shadow: 2px 2px 0 $treemap-text-shadow;
  }

  // Subheading
  p {
    margin-bottom: 0;
    font-size: rem-calc(13);
    line-height: 1.4;
    font-weight: 200;
  }

  &.-largest {
    strong {
      font-size: rem-calc(24);
      line-height: rem-calc(26);
      text-shadow: 3px 3px 0 $treemap-text-shadow;
    }

    p {
      font-size: rem-calc(18);
      line-height: rem-calc(27);
    }
  }

  // TODO:
  // @include media-query(tablet) {
  //   &__canvas__highlightLabel {
  //     strong {
  //       font-size: rem-calc(20);
  //     }

  //     p {
  //       font-size: rem-calc(16);
  //     }
  //   }
  // }
}

//
// Large Container Layout
//

.container--large {
  padding-bottom: 0;

  .canvas {
    border-bottom-right-radius: calc(#{$global-radius} + 1px);
    border-bottom-left-radius: calc(#{$global-radius} + 1px);
  }
}

.canvas {
  flex-grow: 1;
  flex-shrink: 0;

  // Hide chart while loader is showing
  &--hide {
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }
}

.loader {
  flex-grow: 1;
  @extend %chartLoaderContainer;

  svg {
    flex-grow: 1;
  }
}
