@import '/src/styles/index.scss';

// Elements

.container {
  border: 1px solid $gray-30;
  padding: _var(--global-sm-padding);
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: _var(--global-sm-padding);
}

.field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: _var(--global-xsm-padding);

  &--top {
    grid-column-start: 2;
  }
  &--left {
    grid-row-start: 2;
    flex-direction: column-reverse;
  }
  &--bottom {
    grid-column-start: 2;
    grid-row-start: 3;
  }
  &--right {
    grid-column-start: 3;
    grid-row-start: 2;
    flex-direction: column-reverse;
  }
  &--middle {
    grid-column-start: 2;
    grid-row-start: 2;
  }
}

.clearField {
  border: 1px solid $gray-30;
  padding: _var(--global-padding);

  button {
    color: $gray-50;

    &:hover {
      color: $gray-80;
    }
  }
}

.input {
  width: rem-calc(42);
  padding: 0;
  text-align: center;
  appearance: none;
  outline: none;
  border: 1px solid transparent;
  transition: border-color 0.1s linear;
  line-height: rem-calc(20);

  &:hover {
    border-color: $gray-30;
  }

  &:focus {
    border-color: $gray-80;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.button {
  color: $gray-50;

  &:hover {
    color: $gray-80;
  }
}
