@import '../../../../../styles/index.scss';

.panel {
  background-color: $white;

  &--hidden {
    opacity: 0;
  }

  &--dragging {
    z-index: $subNavIndex - 5;
    position: relative;
    outline: 3px solid $blue-info;
    box-shadow: 0 0 rem-calc(12) rgba(0, 0, 0, 0.45);

    .heading {
      border-width: 0;
    }
  }

  &__content {
    max-height: 0;
    padding: 0 _var(--global-padding);
    position: relative;
    transform: translate(0, 0);
    transition: opacity 250ms linear;
    border-bottom: 1px solid $gray-20;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    z-index: 2;
  }

  // Prevent bottom double line
  &:last-child .heading,
  &:last-child &__content {
    border-bottom-width: 0;
  }
}

.sortHandle {
  width: _var(--global-padding);
  height: _var(--global-padding);
  margin-right: _var(--global-sm-padding);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  > svg {
    max-width: 100%;
    max-height: 100%;

    circle {
      stroke: $gray-30;
    }
  }
}

.isOpen {
  &:not(&--noIndicator) {
    @extend %indicatorIcon;
  }

  .panel__content {
    max-height: rem-calc(2000);
    padding-top: _var(--global-padding);
    padding-bottom: _var(--global-lg-padding);
    visibility: visible;
    opacity: 1;
  }

  &.panel--fullHeight .panel__content {
    max-height: unset;
  }

  & > .heading {
    border-bottom-width: 1px !important; // Force last header border
    background: $blue-light;

    .heading__icon {
      transform: rotate(180deg);
    }
  }
}

.heading {
  @extend %accordionHeading;

  &--sortable {
    padding-left: _var(--global-md-padding);
  }

  &__text {
    @extend %accordionHeadingText;
    display: flex;
    align-items: center;

    > svg {
      margin-left: _var(--global-sm-padding);
    }
  }

  &__caption {
    margin-right: auto;
    display: flex;
    align-items: center;

    > span > span {
      z-index: $primaryNavFlyOutIndex;
    }
  }

  &__warning {
    display: inline-block;
    margin-right: _var(--global-sm-padding);
  }

  &__warningIcon {
    display: block;
    width: rem-calc(8);
    height: rem-calc(8);
    background-color: $warning-color;
    border-radius: 100%;
  }

  &__warning + &__text {
    // display: inline-block;
    // padding-left: _var(--global-sm-padding);
  }

  &__icon {
    @extend %accordionHeadingIcon;
  }
}
