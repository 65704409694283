@import '../../../../../../styles/index.scss';

.container {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr;
  grid-gap: _var(--global-md-padding) _var(--global-lg-padding);
  list-style: none;

  &.-twoColumns,
  &.-threeColumns {
    grid-auto-flow: column;
  }

  &.-twoColumns {
    grid-template-columns: repeat(2, auto);
    // TODO: use _var and apply namespacing
    grid-template-rows: repeat(var(--two-col-rows), 1fr);
  }

  &.-threeColumns {
    grid-template-columns: repeat(3, auto);
    // TODO: use _var and apply namespacing
    grid-template-rows: repeat(var(--three-col-rows), 1fr);
  }
}
