%indicatorIcon {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: rem-calc(-6);
    left: rem-calc(-5);
    display: block;
    width: rem-calc(14);
    height: rem-calc(14);
    background-color: $blue-accent;
    border-radius: 100%;
  }
}
