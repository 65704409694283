@import '/src/styles/index.scss';

.container {
  height: 100%;
}

.main {
  @include exk-widget-grid;

  // Multi-Column Layouts
  // &--meduimUp {
  //   border: 1px solid $gray-10; // TODO: set from settings
  // }
}

// Single column layout
.cell {
  @include exk-widget-grid-cell;
}
