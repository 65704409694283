@import '../../../../styles/index.scss';

.label {
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  font-size: _var(--small-font-size);
  color: _var(--body-font-color);
  line-height: rem-calc(28);
  white-space: nowrap;
  cursor: pointer;
}

.label:not(:last-child) {
  margin-right: _var(--padding-2);
}

.input {
  margin-right: _var(--padding-1);
  transform: translateY(#{rem-calc(1)});
}
