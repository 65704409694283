@import '/src/styles/index.scss';

.container {
  max-width: 100%;
}

.main {
  font-family: _var(--body-font-family);
  color: _var(--body-font-color);
  padding-bottom: _var(--global-xlg-padding);

  &__heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: _var(--heading-font-family, _var(--body-font-family));
    font-size: _var(--heading-font-size, _var(--font-size-xl-3_5));
    line-height: _var(--heading-line-height, _var(--line-height-xl-3_5));
    font-weight: _var(--heading-font-weight, _var(--font-semibold));
    color: _var(--heading-font-color, _var(--body-font-color));
  }

  &__subheading {
    font-family: _var(--subheading-font-family, _var(--body-font-family));
    font-size: _var(--subheading-font-size, _var(--body-font-size));
    line-height: _var(--subheading-line-height, _var(--body-line-height));
    font-weight: _var(--subheading-font-weight, _var(--body-font-weight));
    color: _var(--subheading-font-color, _var(--gray-600));
  }

  // &__aside {
  //   flex: 1 0 auto;
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: center;
  //   padding-right: _var(--global-xlg-padding);

  //   .container--twoColumns &,
  //   .container--threeColumns & {
  //     padding-right: _var(--global-xlg-padding);
  //   }
  // }
}

// .promptText {
//   margin: 0 _var(--global-padding) 0;
//   color: $gray-80;
//   font-size: $small-font-size;
// }

// Required style for
// custom color config
// .action {
//   cursor: pointer;
// }
