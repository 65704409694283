@import '/src/styles/index.scss';

.container {
  position: relative; // Position sticky header
  overflow: auto;
  min-height: 100%;
  height: _var(--height);
  max-height: _var(--max-height);
  @include exk-declare-padding-variables;
  @include exk-declare-border-variables;
  background: _var(--background-color);

  &__header {
    @extend %visualizationsCompareHeader;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1;

    // Vertical dividing line
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      width: 1px;
      background: $gray-20;
      z-index: 1;
    }

    // Circular "VS" icon
    &::after {
      content: 'VS';
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: rem-calc(24);
      height: rem-calc(24);
      line-height: rem-calc(24);
      font-size: rem-calc(11);
      text-align: center;
      color: $gray-40;
      background: $white;
      border: 1px solid $gray-20;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  &__list {
    @extend %visualizationsCompareList;
  }
}

.item {
  @extend %visualizationsCompareListItem;
  padding-right: _var(--padding-right);
  padding-left: _var(--padding-left);
  border-bottom: 1px solid $gray-10;

  // Remove border from summary
  // and item before summary
  &:last-child,
  &:nth-last-child(2) {
    border-bottom-width: 0;
  }

  &--summary {
    position: sticky;
    bottom: 0;
    padding-top: _var(--global-md-padding);
    padding-bottom: _var(--global-md-padding);
    box-shadow: none;
    background-color: $white;
    border-top: 1px solid $gray-10;
  }

  &--summaryHover {
    border-top: none;
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.08);
  }
}

.itemLoader {
  height: rem-calc(15);
  @extend %aniLoading;
}

//
// Elements
//

.graphic {
  @extend %visualizationsCompareListItemGraphicRow;
}

.tooltipIcon {
  max-width: rem-calc(13);
  max-height: rem-calc(13);
  margin-left: rem-calc(3);
}

//
// Typography
//

.heading {
  @extend %visualizationsCompareHeading;
  border-bottom: 1px solid $gray-20;
}

.resultTitle {
  @extend %visualizationsCompareListItemTitle;

  &--tooltip {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: rem-calc(3);
    text-decoration-color: $gray-30;
    cursor: pointer;
  }
}

.summaryTitle {
  @extend %visualizationsCompareListItemTitle;
  font-size: rem-calc(15);
  margin-bottom: rem-calc(6);
}

//
// Large Container Layout
//

// Replace full-width appearance
// with restrained bordered container
.container--large {
  margin-right: _var(--padding-right);
  margin-left: _var(--padding-left);

  .item {
    padding-right: _var(--global-xlg-padding);
    padding-left: _var(--global-xlg-padding);
  }
}
