///////////////////////
// Savings | Compare
///////////////////////

%visualizationsCompareHeader {
  display: flex;
  height: rem-calc(58);
  position: relative;
  text-align: center;
}

%visualizationsCompareHeading {
  display: block;
  flex: 1 1 50%;
  padding: _var(--global-padding);
  border-bottom: 1px solid $gray-10;
  line-height: _var(--global-padding);
  font-family: $body-font-family;
  font-size: $font-size;
  font-weight: $font-weight-bold;
  color: $gray-80;
}

%visualizationsCompareList {
  list-style: none;
  padding: 0;
}

%visualizationsCompareListItem {
  display: flex;
  flex-wrap: wrap;
  padding: _var(--global-sm-padding);
  justify-content: center;
}

%visualizationsCompareListItemTitle {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  margin-bottom: rem-calc(4);
  line-height: rem-calc(15);
  color: rgba($gray-80, 80%);
  font-weight: $font-weight-bold;
  font-size: rem-calc(12);
  text-align: center;
}

%visualizationsCompareListItemGraphicRow {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: rem-calc(13);
  color: rgba($gray-90, 60%);
}

%visualizationsCompareGraphicColumn {
  flex: 1 1 50%;
  justify-content: flex-start;
  padding-bottom: _var(--global-xsm-padding);

  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;

  &.-left {
    justify-content: flex-end;
  }
}

%visualizationsCompareGraphicColumn__amount {
  flex: 0 1 auto;
  position: relative;
  top: rem-calc(3);
  line-height: _var(--global-md-padding);
  color: #525252;
  font-size: 0.875rem;
}

%visualizationsCompareGraphicColumn__bar {
  flex: 1 1 auto;
  position: relative;
  height: rem-calc(6);
  max-width: rem-calc(152);
  overflow: hidden;

  .-is-last & {
    height: rem-calc(7);
  }
}

%visualizationsCompareGraphicColumn__bar--left {
  margin-right: rem-calc(1);
}

%visualizationsCompareGraphicColumn__bar--right {
  margin-left: rem-calc(1);
}

%visualizationsCompareGraphicColumn__bar__value {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: $red-primary;
}

%visualizationsCompareGraphicColumn__bar__value--left {
  left: 100%;
}

%visualizationsCompareGraphicColumn__bar__value--right {
  left: -100%;
}

%visualizationsCompareGraphicColumn__bar__value--isLesser {
  background-color: $red-secondary;
}

%visualizationsCompareGraphicColumn__bar__value--isBigCost {
  background-color: $red-primary;
}

%visualizationsCompareGraphicColumn__bar__value--isBigSaver {
  background-color: $green-teirtary;
}
