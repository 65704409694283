@import '../../../../styles/index.scss';

.container {
  position: relative;

  &__icon {
    width: _var(--padding-5);
    height: _var(--padding-5);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    svg {
      path {
        fill: $gray-30;
      }
    }

    &--left {
      left: _var(--padding-4);
    }

    &--right {
      right: _var(--padding-4);
    }
  }
}

.input {
  height: _var(--input-height, _var(--padding-12)); // 48px

  &--leftIcon {
    padding-left: _var(--padding-7);
  }

  &--rightIcon {
    padding-right: _var(--padding-12); // 48px
  }
}
