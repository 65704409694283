// Source: https://github.com/drublic/Sass-Mixins/blob/master/partials/_appearance.scss
@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin disable-text-selection {
  -moz-user-select: none; // Firefox
  -webkit-user-select: none; // Safari
  user-select: none; // Non-prefixed version, currently supported by Chrome and Opera
}
