@import '../../../../../../../styles/index.scss';

.item {
  @extend %visualizationsCompareGraphicColumn;
  display: flex;
  flex: 1;
  align-items: flex-end;

  &__amount {
    @extend %visualizationsCompareGraphicColumn__amount;

    &--left {
      margin-right: rem-calc(8);
    }

    &--right {
      margin-left: rem-calc(8);
    }
  }
}

.bar {
  @extend %visualizationsCompareGraphicColumn__bar;
  max-width: 100%;
  margin: 0 1px;

  &__value {
    @extend %visualizationsCompareGraphicColumn__bar__value;
    transition: transform linear 0.2s;

    &--left {
      @extend %visualizationsCompareGraphicColumn__bar__value--left;
    }

    &--right {
      @extend %visualizationsCompareGraphicColumn__bar__value--right;
    }

    &--isLesser {
      @extend %visualizationsCompareGraphicColumn__bar__value--isLesser;
    }

    &--isBigCost {
      @extend %visualizationsCompareGraphicColumn__bar__value--isBigCost;
    }

    &--isBigSaver {
      @extend %visualizationsCompareGraphicColumn__bar__value--isBigSaver;
    }
  }

  &--summary {
    height: rem-calc(7);
  }

  &--loading {
    @extend %aniLoading;
  }
}
